import { Box, Button, Collapsible, Layer, Nav, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const LANGUAGES = ['arabic', 'japanese'];

/**
 * Capitalizes the first letter of a label
 *
 * @function formatLabel
 * String -> String
 * @param {String} label
 * @returns {String}
 */
const formatLabel = (label = '') =>
  label.charAt(0).toUpperCase() + label.slice(1);

/**
 * Controls what happens when you click a sidebar button
 *
 * @function onClickHandler
 * @param {Function} setActiveLanguage
 * @param {String} language
 * @param {Function} setShowSidebar
 */
const onClickHandler = (setActiveLanguage, language = '', setShowSidebar) => {
  setActiveLanguage(language.toLowerCase());
  setShowSidebar(false);
};

const SidebarButton = ({ label, ...rest }) => (
  <Button plain {...rest}>
    {({ hover }) => (
      <Box
        background={hover ? 'brand' : undefined}
        pad={{ horizontal: 'large', vertical: 'medium' }}
      >
        <Text size="large">{label}</Text>
      </Box>
    )}
  </Button>
);

export const SideBar = ({
  showSidebar,
  size,
  setShowSidebar,
  activeLanguage,
  setActiveLanguage,
}) =>
  !showSidebar || size !== 'small' ? (
    <Collapsible direction="horizontal" open={showSidebar}>
      <Box
        flex
        fill
        direction="row"
        width="medium"
        elevation="small"
        // align="left"
        // justify="start"
      >
        <Nav background="accent">
          {LANGUAGES.sort().map((label) => (
            <SidebarButton
              key={label}
              label={<Text color="white">{formatLabel(label)}</Text>}
              active={label === activeLanguage}
              onClick={() =>
                onClickHandler(setActiveLanguage, label, setShowSidebar)
              }
            />
          ))}
        </Nav>
      </Box>
    </Collapsible>
  ) : (
    <Layer>
      <Box
        background="accent"
        tag="header"
        justify="end"
        align="center"
        direction="row"
      >
        <Button icon={<FormClose />} onClick={() => setShowSidebar(false)} />
      </Box>
      <Box fill background="accent" align="center" justify="start">
        <Nav background="accent" fill align="center">
          {LANGUAGES.sort().map((label) => (
            <SidebarButton
              fill="horizontal"
              key={label}
              label={<Text color="white">{formatLabel(label)}</Text>}
              active={label === activeLanguage}
              onClick={() =>
                onClickHandler(setActiveLanguage, label, setShowSidebar)
              }
            />
          ))}
        </Nav>
      </Box>
    </Layer>
  );
