import { Box, Button, Form, FormField, Spinner, Text, TextArea } from 'grommet';
import React, { useState } from 'react';

const handleFormChange = (newFormState) => {
  console.log({ newFormState });
};

export const TranslationBox = ({ size }) => {
  const [spinner, setSpinner] = useState(false);
  const [foreignText, setForeignText] = useState(
    'あなたはバカです あなたはバカです あなたはバカです'
  );

  return spinner ? (
    <Box
      direction="row"
      fill
      flex
      justify="center"
      align="center"
      overflow={{ horizontal: 'hidden' }}
    >
      <Spinner size="medium" color="brand" />
      <Text>&emsp;Validating translation ...</Text>
    </Box>
  ) : (
    <Box
      fill
      flex
      justify="center"
      align="center"
      overflow={{ horizontal: 'hidden' }}
    >
      <Box
        pad="large"
        fill="vertical"
        width={size === 'small' ? '94%' : '80%'}
        wrap
        justify="center"
        align="center"
      >
        <Text size={size === 'small' ? 'large' : '2xl'} weight="bold">
          {foreignText}
        </Text>
      </Box>
      <Box
        pad="large"
        fill="vertical"
        width={size === 'small' ? '80%' : '60%'}
        justify="start"
        align="center"
      >
        <Form
          style={{ width: '100%' }}
          validate="blur"
          onChange={handleFormChange}
          onValidate={(validationResults) => {
            console.log('validationResults = ', validationResults);
          }}
          onSubmit={(event) => {
            console.log(event);
            console.log('Submit', event.value, event.touched);
          }}
        >
          <FormField
            htmlFor="text-area"
            component={TextArea}
            placeholder="Enter your translation"
          />
          <Button
            type="submit"
            label="submit"
            hoverIndicator
            primary
            onClick={() => {
              setSpinner(true);
              setTimeout(() => setSpinner(false), 2000);
            }}
          />
        </Form>
      </Box>
    </Box>
  );
};
