import { Box, Heading, Button } from "grommet";
import { Menu } from "grommet-icons";

const TopBar = (props) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    style={{ zIndex: "1" }}
    {...props}
  />
);

export const AppBar = ({ setShowSidebar, showSidebar }) => (
  <TopBar background="brand">
    <Heading level="3" margin="none">
      LANGUAGE-AID
    </Heading>
    <Button icon={<Menu />} onClick={() => setShowSidebar(!showSidebar)} />
  </TopBar>
);
