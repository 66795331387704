import { Box, Grommet, ResponsiveContext } from 'grommet';
import React, { useState } from 'react';
import { AppBar, SideBar, SplashPage, TranslationBox } from './components';

const theme = {
  global: {
    colors: {
      brand: 'rgb(215,55,55)',
      accent: 'rgb(15,45,167)',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

function App() {
  const [showSplashPage, setShowSplashPage] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('japanese');

  return (
    <Grommet theme={theme} full themeMode="dark">
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box fill>
            <AppBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <Box direction="row" fill flex overflow={{ horizontal: 'hidden' }}>
              {showSplashPage ? (
                <SplashPage setShowSplashPage={setShowSplashPage} />
              ) : (
                <TranslationBox size={size} />
              )}
              <SideBar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                size={size}
                activeLanguage={activeLanguage}
                setActiveLanguage={setActiveLanguage}
              />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
