/* eslint-disable no-lone-blocks */
import { Box, Button, Carousel, Text } from 'grommet';
import { Compare, Next, Test } from 'grommet-icons';

export const SplashPage = ({ setShowSplashPage }) => {
  return (
    <Box fill align="center" justify="center" overflow={{ vertical: 'hidden' }}>
      <Box height="10%" />
      <Box
        fill="vertical"
        width="86%"
        justify="center"
        align="center"
        pad="large"
      >
        <Carousel wrap fill>
          <Box
            flex
            fill
            align="center"
            justify="center"
            background="light-3"
            gap="large"
          >
            <Box
              fill="horizontal"
              style={{ 'padding-top': '20%', 'padding-bottom': '20%' }}
              align="center"
            >
              <Test size="xlarge" />
            </Box>
            <Box
              style={{ 'padding-bottom': '10%' }}
              fill="vertical"
              width="70%"
              align="center"
              wrap
            >
              <Text size="large">
                Test your knowledge of foreign languages!
              </Text>
            </Box>
          </Box>
          <Box
            flex
            fill
            align="center"
            justify="center"
            background="light-3"
            gap="large"
          >
            <Box
              fill="horizontal"
              style={{ 'padding-top': '20%', 'padding-bottom': '20%' }}
              align="center"
            >
              <Compare size="xlarge" />
            </Box>
            <Box
              width="70%"
              align="center"
              wrap
              fill="vertical"
              style={{ 'padding-bottom': '10%' }}
            >
              <Text size="large">
                We give you a foreign sentence, and compare the actual
                translation to yours.
              </Text>
            </Box>
          </Box>
        </Carousel>
      </Box>
      <Box height="10%" />
      <Box height="20%" width="40%" align="center" justify="start">
        <Button
          primary
          fill="horizontal"
          label="Translate"
          icon={<Next />}
          reverse
          onClick={() => {
            setShowSplashPage(false);
          }}
        />
      </Box>
    </Box>
  );
};

{
  /* <Box
          fill
          pad="small"
          gap="small"
          direction="row-responsive"
          align="center"
          justify="center"
        >
          {/* <Box
            pad="large"
            background="linear-gradient(102.77deg, #FF0000 -9.18%, #FFFFFF 209.09%)"
            border="horizontal"
            elevation="medium"
            round="xsmall"
          >
            test
          </Box>
          <Box
            pad="large"
            background="light-1"
            border="horizontal"
            elevation="medium"
            round="xsmall"
          >
            test
          </Box> 
          <InfoCard />
          <InfoCard />
        </Box>
        <Box
          fill
          pad="small"
          gap="small"
          direction="row-responsive"
          align="center"
          justify="center"
        >
          <InfoCard />
          <InfoCard />
        </Box>*/
}
